.banner-container {
  --animation-duration: 35s;
  background-color: white;
  color: black;
  overflow: hidden;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative; /* Contenedor relativo para que el texto se posicione absoluto dentro de él */
}

@media (max-width: 1024px) {
  .banner-container {
    --animation-duration: 15s;
  }
}
.banner-content {
  display: inline-block;
  white-space: nowrap;
  position: absolute; /* Se mueve relativo al contenedor */
  animation: slideInOut var(--animation-duration) linear infinite;
  font-size: 0.8rem;
  color: black;
}

@keyframes slideInOut {
  0% {
    left: 100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

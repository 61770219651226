[data-theme="dark"] {
  --hightlight-color: #ff4d4d;
}

[data-theme="light"] {
  --hightlight-color: #b30000;
}

.fixturesList {
  flex: 1 1 auto;
  overflow-y: auto;
  font-size: 0.8rem;
}

@keyframes dynamicHighlight {
  0% {
    transform: translateX(2px);
    color: #ff0000f3;
  }
  50% {
    transform: translateX(-2px);
    color: var(--hightlight-color)
  }
  100% {
    transform: translateX(2px);
    color: #ff0000f3;
  }
}

.playing {
  animation: dynamicHighlight 2s infinite;
}
.footer {
  width: 100%;
  position: relative;
  background: linear-gradient(var(--primary-color) 40%, var(--secondary-color) 100%);
  margin-top: 14rem;
  font-size: 0.9rem;

  @media (max-width: 1199px) {
    margin-top: 5rem;
  }
}

.custom-layout {
  width: 100%;
  min-height: 45rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  @media (min-width: 576px) {
    max-width: 700px;
  }
  @media (min-width: 768px) {
    max-width: 950px;
  }
  @media (min-width: 992px) {
    max-width: 1150px;
  }
  @media (min-width: 1200px) {
    max-width: 1300px;
  }
  @media (min-width: 1400px) {
    max-width: 1420px;
  }

  /* Estilos de d-flex */
  display: flex;

  /* Estilos de flex-column */
  flex-direction: column;

  /* Estilos de flex-lg-row */
  @media (min-width: 1200px) {
    flex-direction: row;
  }

  /* Estilos de g-0 */
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.header {
  width: 100%;
  position: sticky;
  top: 0;
  background: linear-gradient(var(--secondary-color) 20%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(7px);    
  z-index: 200000;
}

.logo {
  display: none;

  @media (max-width: 767px) {
    width: 2.8rem;
  }
}

.word-logo {
  display: none;

  @media (max-width: 767px) {
    width: 6rem;
  }
}

.dropdown-menu-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;

  &.open {
    max-height: fit-content; // Ajusta según la altura de tu menú
    opacity: 1;
  }

  &.close {
    max-height: 0;
    opacity: 0;
  }
}


